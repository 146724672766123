import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { Toolbar, Grid, Box, Typography, Link } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Select from "react-select";
import "main.css";
const supported_lang = [
  { value: "ar", label: "IQ" ,img: "https://flagcdn.com/w40/iq.png"},
  { value: "en", label: "EN" },
];
const supported_curr = [
  { value: "iqd", label: "IQD" },
  { value: "usd", label: "USD" },
];
const customSingleValue = ({ data }) => (
  <div className="op-en">
    {data.img? (
      <img
        src={data.img}
        alt=""
      />
    ) :data.label }
  </div>
);

const customOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
    
    >
      {data.img ? (
        <img
          src={data.img}
          alt=""
        />
      ) : (data.label)}
      
    </div>
  );
};
const getPrimaryLang = () => {
  const languages = navigator.languages;
  let primary_lang =
    supported_lang.find((p) => languages.includes(p.value)) ||
    supported_lang[0];

  if (
    localStorage.getItem("language") &&
    JSON.parse(localStorage.getItem("language")).value
  ) {
    primary_lang = JSON.parse(localStorage.getItem("language"));
  }
  return primary_lang;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    icon: {
      fill: "#ffffff",
    },
    appbar: {
      margin: 0,
      padding: 0,
    },
    menuButton: {
      margin: theme.spacing(1),
      padding: 0,
    },
    title: {
      paddingTop: 15,
    },
    socialtoolbar: {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
    select: {
      fontFamily: "inherit",

      "& button": {
        color: "#ffffff",
        borderColor: "#fff",
        width: 200,
        overflow: "hidden",
      },
      "& .ReactFlagsSelect-module_selectOptionValue__vS99-": {
        color: "#000",
      },
      "& .ReactFlagsSelect-module_selectBtn__19wW7:after": {
        borderTop: "5px solid #fff",
      },
      '& .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after':
      {
        borderBottom: "5px solid #fff",
      },
    },
    socialCls: {
      color: "#ffffff",
    },
    topbarLeft: {
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

const Topbar = ({ i18n, t, config }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useNavigate();
  const [language, setLanguage] = useState(getPrimaryLang());
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency")
      ? JSON.parse(localStorage.getItem("currency"))
      : supported_curr[0]
  );

  useEffect(() => {
    let subscribe = true;
    const asyncCall = async () => {
      if (subscribe && language) {
        if (language.value === "ar") {
          document.getElementById("root").dir = "rtl";
          document.getElementById("root").classList.add("arabic");
        } else {
          document.getElementById("root").classList.remove("arabic");
          document.getElementById("root").dir = "";
        }
        i18n.changeLanguage(language.value);
        localStorage.setItem("language", JSON.stringify(language));
      }
    };
    asyncCall();
    return () => {
      subscribe = false;
    };
  }, [JSON.stringify(language)]);

  const handleLangChange = (lang) => {
    // const lang_data = supported_lang.filter((value) => {
    //   if (value.label == lang) {
    //     return value;
    //   }
    // });
    localStorage.setItem("language", JSON.stringify(lang));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const handleCurrChange = (curr) => {
    localStorage.setItem("currency", JSON.stringify(curr));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange0 = (event) => {
    setAge(event.target.value);
  };
  const [selected, setSelected] = useState("");

  return (
    <Toolbar className={classes.socialtoolbar + " gray"}>
      <Grid container>
        <Grid container item xs={3} sm={3} md={2} lg={2} alignItems="center">
          <NavLink to="/">
            <img
              src="/logo.png"
              className="topsidelogo"
              width="150px"
              height="53px"
            />
          </NavLink>
        </Grid>
        <Grid container item xs={1} sm={1} md={6} lg={7} alignItems="center">
          <Typography className="supports">
            {t("Support and Maintenance")}:{" "}
            <Link to="">
              {config && config.admin_phone.replace("+964 ", "0")}
            </Link>{" "}
             |{" "}
            {config && (
              <Link href={"mailto:" + config?.admin_email}>
                {config?.admin_email}
              </Link>
            )}{" "}
            | <Link>{t("Cash on Delivery")}</Link>
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={3} md={4} lg={3}>
          <Box className="rightheader">
            <Box
              component="div"
              m={1}
              className="selectlanguage"
              style={{ minWidth: 180, color: "#111" }}
            >
              <Select
                value={currency}
                options={supported_curr}
                className="custom-select"
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: null,
                }}
                onChange={(curr) => {
                  handleCurrChange(curr);
                }}
                placeholder={t(currency.label)}
              />
            </Box>
            <Box
              component="div"
              m={1}
              className="selectlanguage"
              style={{ minWidth: 180, color: "#111" }}
            >
              <Select
                value={language}
                options={supported_lang}
                className="custom-select"
                 components={{
                  SingleValue: customSingleValue,
                  Option: customOption,
                  IndicatorSeparator: null,
                  DropdownIndicator: null,
                }} 
                onChange={(curr) => {
                  handleLangChange(curr);
                }}
                placeholder="Select Language"
              />
            </Box>
            
            
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
};
const mapStateToProps = (state) => ({
  config: state.config.detail,
});
export default withTranslation()(connect(mapStateToProps)(Topbar));
